import { render } from "./profile.vue?vue&type=template&id=85478584"
import script from "./profile.vue?vue&type=script&lang=js"
export * from "./profile.vue?vue&type=script&lang=js"

import "./profile.vue?vue&type=style&index=0&id=85478584&lang=scss"
script.render = render
/* custom blocks */
import block0 from "./profile.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(script)


export default script